import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const MentoringFormFront = ({
	handleNext,
	handleBack,
	handleSubmit,
	currentStep,
	selectedStructures,
}) => {
	const [submited, setSubmited] = useState(false)
	const [errors, setErrors] = useState({})
	const [textareaRows2, setTextAreaRows2] = useState({})
	const navigate = useNavigate()

	const [answersForm, setAnswersForm] = useState(() => {
		const storedAnswers = localStorage.getItem('answersFormMentoringFront')
		return storedAnswers
			? JSON.parse(storedAnswers)
			: {
					tasks_Mentoring_Front: '',
					languages_Mentoring_Front: '',
					important_Mentoring_Front: '',
					browser_Mentoring_Front: '',
					sides_Mentoring_Front: '',
					react_Mentoring_Front: '',
					active_Mentoring_Front: '',
					future_Mentoring_Front: '',
					tryhard_Mentoring_Front: '',
			  }
	})

	const handleChange = e => {
		const { name, value, scrollHeight, clientHeight } = e.target
		const uniqueId = e.target.id
		if (scrollHeight > clientHeight) {
			e.target.rows += 1
		}
		if (e.target.type === 'radio') {
			setAnswersForm(prevState => ({
				...prevState,
				languages_Front: {
					...prevState.languages_Front,
					[name]: value,
				},
			}))
		} else {
			setAnswersForm(prevState => ({
				...prevState,
				[name]: value,
			}))
		}
		// Обновите количество строк в каждой textarea
		setTextAreaRows2(prevState => ({
			...prevState,
			[uniqueId]: e.target.rows,
		}))
		localStorage.setItem(
			'textareaRowsMentoringFront',
			JSON.stringify({ ...textareaRows2, [uniqueId]: e.target.rows })
		)
		localStorage.setItem(
			'answersFormMentoringFront',
			JSON.stringify({
				...answersForm,
				[name]: value,
			})
		)
		setErrors(prevState => ({
			...prevState,
			[name]: '',
		}))
	}

	useEffect(() => {
		const savedRows = localStorage.getItem('textareaRowsMentoringFront')
		if (savedRows) {
			const textareas = document.querySelectorAll('.dynamic-textarea')
			textareas.forEach(textarea => {
				const name = textarea.getAttribute('name')
				textarea.rows = parseInt(JSON.parse(savedRows)[name] || 1)
			})
		}
	}, [])

	useEffect(() => {
		const storedAnswers = localStorage.getItem('answersFormMentoringFront')
		if (storedAnswers) {
			setAnswersForm(JSON.parse(storedAnswers))
		}
	}, [])

	const onSubmit = async () => {
		const requiredFields = [
			'tasks_Mentoring_Front',
			'languages_Mentoring_Front',
			'important_Mentoring_Front',
			'browser_Mentoring_Front',
			'sides_Mentoring_Front',
			'react_Mentoring_Front',
			'active_Mentoring_Front',
			'future_Mentoring_Front',
			'tryhard_Mentoring_Front',
		]

		const emptyFields = requiredFields.filter(
			key => !answersForm[key] || answersForm[key].trim() === ''
		)

		if (emptyFields.length > 0) {
			const newErrors = {}
			emptyFields.forEach(field => {
				newErrors[field] = 'Заполните это поле'
			})
			setErrors(newErrors)
			return
		}

		const frontData = {
			cases: answersForm.tasks_Mentoring_Front,
			technologies: answersForm.languages_Mentoring_Front,
			important: answersForm.important_Mentoring_Front,
			browser_work: answersForm.browser_Mentoring_Front,
			pros_cons: answersForm.sides_Mentoring_Front,
			react_tasks: answersForm.react_Mentoring_Front,
			experience: answersForm.active_Mentoring_Front,
			after_mentoring: answersForm.future_Mentoring_Front,
			hardworking: answersForm.tryhard_Mentoring_Front,
		}

		await handleSubmit(frontData)
	}

	return (
		<>
			<div className='Form Form_Frontend'>
				<form
					onSubmit={e => {
						e.preventDefault()
						onSubmit()
					}}
					className='survival-school-form'
				>
					<h2 className='p_Front'>Вопросы на Frontend</h2>

					<div>
						<label htmlFor='tasks_Mentoring_Front'>
							Чем по твоему мнению занимается frontend разработчик? Какие у него
							задачи и зоны ответственности? Этот вопрос больше для тебя "на
							подумать", поэтому ничего страшного, если не знаешь наверняка,
							можно не гуглить, а просто предположить
						</label>
						<textarea
							id='tasks_Mentoring_Front'
							name='tasks_Mentoring_Front'
							value={answersForm.tasks_Mentoring_Front}
							onChange={handleChange}
							rows={4}
							style={{ resize: 'none' }}
						/>
						{errors.tasks_Mentoring_Front && (
							<p className='error-message'>{errors.tasks_Mentoring_Front}</p>
						)}
					</div>
					<div>
						<label htmlFor='languages_Mentoring_Front'>
							Какие технологии frontend ты уже знаешь или пробовал использовать
							(например, HTML, CSS, JavaScript)?
						</label>
						<textarea
							id='languages_Mentoring_Front'
							name='languages_Mentoring_Front'
							value={answersForm.languages_Mentoring_Front}
							onChange={handleChange}
							rows={4}
							style={{ resize: 'none' }}
						/>
						{errors.languages_Mentoring_Front && (
							<p className='error-message'>
								{errors.languages_Mentoring_Front}
							</p>
						)}
					</div>
					<div>
						<label htmlFor='important_Mentoring_Front'>
							Что для тебя наиболее важно в процессе обучения?
						</label>
						<textarea
							id='important_Mentoring_Front'
							name='important_Mentoring_Front'
							value={answersForm.important_Mentoring_Front}
							onChange={handleChange}
							rows={4}
							style={{ resize: 'none' }}
						/>
						{errors.important_Mentoring_Front && (
							<p className='error-message'>
								{errors.important_Mentoring_Front}
							</p>
						)}
					</div>
					<div>
						<label htmlFor='browser_Mentoring_Front'>
							Знаешь ли ты основы работы браузеров или как веб-страница
							отображается пользователю? Попробуй описать так, как ты это
							понимаешь
						</label>
						<textarea
							id='browser_Mentoring_Front'
							name='browser_Mentoring_Front'
							value={answersForm.browser_Mentoring_Front}
							onChange={handleChange}
							rows={4}
							style={{ resize: 'none' }}
						/>
						{errors.browser_Mentoring_Front && (
							<p className='error-message'>{errors.browser_Mentoring_Front}</p>
						)}
					</div>
					<div>
						<label htmlFor='sides_Mentoring_Front'>
							Какие твои сильные и слабые стороны?
						</label>
						<textarea
							id='sides_Mentoring_Front'
							name='sides_Mentoring_Front'
							value={answersForm.sides_Mentoring_Front}
							onChange={handleChange}
							rows={4}
							style={{ resize: 'none' }}
						/>
						{errors.sides_Mentoring_Front && (
							<p className='error-message'>{errors.sides_Mentoring_Front}</p>
						)}
					</div>
					<div>
						<label htmlFor='react_Mentoring_Front'>
							Пробовал(а) ли ты писать код на React? Если да, то с какими
							задачами сталкивался(ась) (например, добавление интерактивности,
							обработка событий)?
						</label>
						<textarea
							id='react_Mentoring_Front'
							name='react_Mentoring_Front'
							value={answersForm.react_Mentoring_Front}
							onChange={handleChange}
							rows={4}
							style={{ resize: 'none' }}
						/>
						{errors.react_Mentoring_Front && (
							<p className='error-message'>{errors.react_Mentoring_Front}</p>
						)}
					</div>
					<div>
						<label htmlFor='active_Mentoring_Front'>
							Есть ли у тебя опыт студенческой активности? А может, у тебя были
							какие-то проекты в школьные времена, в детских лагерях? В ответе
							можно смело хвастаться всем, чем ты посчитаешь нужным!
						</label>
						<textarea
							id='active_Mentoring_Front'
							name='active_Mentoring_Front'
							value={answersForm.active_Mentoring_Front}
							onChange={handleChange}
							rows={4}
							style={{ resize: 'none' }}
						/>
						{errors.active_Mentoring_Front && (
							<p className='error-message'>{errors.active_Mentoring_Front}</p>
						)}
					</div>
					<div>
						<label htmlFor='future_Mentoring_Front'>
							Есть ли у тебя опыт студенческой активности? А может, у тебя были
							какие-то проекты в школьные времена, в детских лагерях? В ответе
							можно смело хвастаться всем, чем ты посчитаешь нужным!
						</label>
						<textarea
							id='future_Mentoring_Front'
							name='future_Mentoring_Front'
							value={answersForm.future_Mentoring_Front}
							onChange={handleChange}
							rows={4}
							style={{ resize: 'none' }}
						/>
						{errors.future_Mentoring_Front && (
							<p className='error-message'>{errors.future_Mentoring_Front}</p>
						)}
					</div>
					<div>
						<label htmlFor='tryhard_Mentoring_Front'>
							В любом обучении важны постоянство и упорство. Готов(а) ли ты
							регулярно посещать лекции и выполнять домашние задания в рамках
							обучения? А что будешь делать, если понимаешь, что никак не
							успеваешь посетить занятие или сдать что-то в нужное время?
						</label>
						<textarea
							id='tryhard_Mentoring_Front'
							name='tryhard_Mentoring_Front'
							value={answersForm.tryhard_Mentoring_Front}
							onChange={handleChange}
							rows={4}
							style={{ resize: 'none' }}
						/>
						{errors.tryhard_Mentoring_Front && (
							<p className='error-message'>{errors.tryhard_Mentoring_Front}</p>
						)}
					</div>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<button
							className='form__button frontButton'
							onClick={handleBack}
							type='button'
						>
							Назад
						</button>
						<button className='form__button frontButton' type='submit'>
							{currentStep === selectedStructures.length - 1
								? 'Отправить'
								: 'Далее'}
						</button>
					</div>
				</form>
				<div className='running-text-container' id='running-text-container'>
					<pre className='text'>
						<code>ITC СНОВА СДЕЛАЛ КРАСИВО</code>
					</pre>
				</div>
			</div>
		</>
	)
}

export default MentoringFormFront
