import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
const MentoringFormProject = ({
	handleNext,
	handleBack,
	handleSubmit,
	currentStep,
	selectedStructures,
}) => {
	const [submited, setSubmited] = useState(false)
	const [error, setError] = useState('')
	const [textareaRows4, setTextAreaRows4] = useState({})
	const navigate = useNavigate()

	const [answersForm, setAnswersForm] = useState(() => {
		const storedAnswers = localStorage.getItem('answersFormMentoringProject')
		return storedAnswers
			? JSON.parse(storedAnswers)
			: {
					tasks_Mentoring_Project: '',
					teamsIT_Mentoring_Project: '',
					reasonsWhy_Mentoring_Project: '',
					experience_Mentoring_Project: '',
					workExperience_Mentoring_Project: '',
					future_Mentoring_Project: '',
					tryhard_Mentoring_Project: '',
			  }
	})

	const handleChange = e => {
		const { name, value, scrollHeight, clientHeight } = e.target
		const uniqueId = e.target.id

		setAnswersForm(prevState => ({
			...prevState,
			[name]: value,
		}))
		if (scrollHeight > clientHeight) {
			e.target.rows += 1
		}

		localStorage.setItem(
			'textareaRowsMentoringProject',
			JSON.stringify({ ...textareaRows4, [uniqueId]: e.target.rows })
		)
		localStorage.setItem(
			'answersFormMentoringProject',
			JSON.stringify({
				...answersForm,
				[name]: value,
			})
		)
	}

	useEffect(() => {
		const savedRows = localStorage.getItem('textareaRowsMentoringProject')
		if (savedRows) {
			const textareas = document.querySelectorAll('.dynamic-textarea')
			textareas.forEach(textarea => {
				const name = textarea.getAttribute('name')
				textarea.rows = parseInt(JSON.parse(savedRows)[name] || 1)
			})
		}
	}, [])

	useEffect(() => {
		const storedAnswers = localStorage.getItem('answersFormMentoringProject')
		if (storedAnswers) {
			setAnswersForm(JSON.parse(storedAnswers))
		}
	}, [])

	const [errors, setErrors] = useState({})

	const onSubmit = async () => {
		const emptyFields = Object.keys(answersForm).filter(
			key =>
				typeof answersForm[key] === 'string' && answersForm[key].trim() === ''
		)
		if (emptyFields.length > 0) {
			setError('Заполните все поля')
			return
		}

		const projectData = {
			cases: answersForm.tasks_Mentoring_Project,
			it_teams: answersForm.teamsIT_Mentoring_Project,
			why_mentoring: answersForm.reasonsWhy_Mentoring_Project,
			experience: answersForm.experience_Mentoring_Project,
			experience_work: answersForm.workExperience_Mentoring_Project,
			after_mentoring: answersForm.future_Mentoring_Project,
			hardworking: answersForm.tryhard_Mentoring_Project,
		}

		await handleSubmit(projectData)

		if (currentStep < selectedStructures.length - 1) {
			handleNext()
		}
	}

	return (
		<>
			<div className='Form Form_Project'>
				<form
					onSubmit={e => {
						e.preventDefault()
						onSubmit()
					}}
					className='survival-school-form'
				>
					<h2 className='p_Project'>Вопросы на Project</h2>

					<div>
						<label htmlFor='tasks_Mentoring_Project'>
							Как ты думаешь, чем занимается Project Manager, какова его роль в
							команде разработки? Этот вопрос больше для тебя "на подумать",
							поэтому ничего страшного, если не знаешь наверняка, можно не
							гуглить, а просто предположить.
						</label>
						<textarea
							id='tasks_Mentoring_Project'
							name='tasks_Mentoring_Project'
							value={answersForm.tasks_Mentoring_Project}
							onChange={handleChange}
							rows={4}
							style={{ resize: 'none' }}
						/>
						{errors.tasks_Mentoring_Project && (
							<p className='error-message'>{errors.tasks_Mentoring_Project}</p>
						)}
					</div>
					<div>
						<label htmlFor='teamsIT_Mentoring_Project'>
							Что ты в целом знаешь про то, как устроены команды в IT? Пиши
							решительно все, что знаешь, чем больше, тем лучше :)
						</label>
						<textarea
							id='teamsIT_Mentoring_Project'
							name='teamsIT_Mentoring_Project'
							value={answersForm.teamsIT_Mentoring_Project}
							onChange={handleChange}
							rows={4}
							style={{ resize: 'none' }}
						/>
						{errors.teamsIT_Mentoring_Project && (
							<p className='error-message'>
								{errors.teamsIT_Mentoring_Project}
							</p>
						)}
					</div>

					<div>
						<label htmlFor='reasonsWhy_Mentoring_Project'>
							Почему ты решил(а), что тебе подходит именно менторинг, чему бы ты
							хотел(а) научиться (ответ "всему" не подойдет, поскольку чем лучше
							ты сформулируешь свои желания и цели, тем проще тебе будет
							учиться)?
						</label>
						<textarea
							id='reasonsWhy_Mentoring_Project'
							name='reasonsWhy_Mentoring_Project'
							value={answersForm.reasonsWhy_Mentoring_Project}
							onChange={handleChange}
							rows={4}
							style={{ resize: 'none' }}
						/>
						{errors.reasonsWhy_Mentoring_Project && (
							<p className='error-message'>
								{errors.reasonsWhy_Mentoring_Project}
							</p>
						)}
					</div>
					<div>
						<label htmlFor='experience_Mentoring_Project'>
							Есть ли у тебя опыт студенческой активности? А может, у тебя были
							какие-то проекты в школьные времена, в детских лагерях? В ответе
							можно смело хвастаться всем, чем ты посчитаешь нужным!
						</label>
						<textarea
							id='experience_Mentoring_Project'
							name='experience_Mentoring_Project'
							value={answersForm.experience_Mentoring_Project}
							onChange={handleChange}
							rows={4}
							style={{ resize: 'none' }}
						/>
						{errors.experience_Mentoring_Project && (
							<p className='error-message'>
								{errors.experience_Mentoring_Project}
							</p>
						)}
					</div>
					<div>
						<label htmlFor='workExperience_Mentoring_Project'>
							Есть ли у тебя какой-то опыт работы (не важно, в IT или нет)? Если
							да, то расскажи, а если нет, то подумай, с чего бы ты хотел(а)
							начать. Если что, можно отвечать как есть, этот вопрос никак ни на
							что не влияет, честно.
						</label>
						<textarea
							id='workExperience_Mentoring_Project'
							name='workExperience_Mentoring_Project'
							value={answersForm.workExperience_Mentoring_Project}
							onChange={handleChange}
							rows={4}
							style={{ resize: 'none' }}
						/>
						{errors.workExperience_Mentoring_Project && (
							<p className='error-message'>
								{errors.workExperience_Mentoring_Project}
							</p>
						)}
					</div>
					<div>
						<label htmlFor='future_Mentoring_Project'>
							Представь, что ты успешно завершил(а) обучение в менторинге, чем
							бы ты хотел(а) заниматься дальше, например, стать активистом ITC,
							попробовать другую структуру в менторинге или заниматься чем-то
							еще?
						</label>
						<textarea
							id='future_Mentoring_Project'
							name='future_Mentoring_Project'
							value={answersForm.future_Mentoring_Project}
							onChange={handleChange}
							rows={4}
							style={{ resize: 'none' }}
						/>
						{errors.future_Mentoring_Project && (
							<p className='error-message'>{errors.future_Mentoring_Project}</p>
						)}
					</div>
					<div>
						<label htmlFor='tryhard_Mentoring_Project'>
							В любом обучении важны постоянство и упорство. Готов(а) ли ты
							регулярно посещать лекции и выполнять домашние задания в рамках
							обучения? А что будешь делать, если понимаешь, что никак не
							успеваешь посетить занятие или сдать что-то в нужное время?
						</label>
						<textarea
							id='tryhard_Mentoring_Project'
							name='tryhard_Mentoring_Project'
							value={answersForm.tryhard_Mentoring_Project}
							onChange={handleChange}
							rows={4}
							style={{ resize: 'none' }}
						/>
						{errors.tryhard_Mentoring_Project && (
							<p className='error-message'>
								{errors.tryhard_Mentoring_Project}
							</p>
						)}
					</div>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<button
							className='form__button projectButton'
							onClick={handleBack}
							type='button'
						>
							Назад
						</button>
						<button className='form__button projectButton' type='submit'>
							{currentStep === selectedStructures.length - 1
								? 'Отправить'
								: 'Далее'}
						</button>
					</div>
				</form>
				<div className='running-text-container' id='running-text-container'>
					<pre className='text'>
						<code>ITC СНОВА СДЕЛАЛ КРАСИВО</code>
					</pre>
				</div>
			</div>
		</>
	)
}

export default MentoringFormProject
