import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const FormFront = ({
	handleNext,
	handleBack,
	handleSubmit,
	currentStep,
	selectedStructures,
}) => {
	const [submited, setSubmited] = useState(false)
	const [error, setError] = useState('')
	const [isFormIncomplete, setIsFormIncomplete] = useState(false)
	const [textareaRows2, setTextAreaRows2] = useState({})
	const navigate = useNavigate()

	const [answersForm, setAnswersForm] = useState(() => {
		const storedAnswers = localStorage.getItem('answersFormFront')
		return storedAnswers
			? JSON.parse(storedAnswers)
			: {
					projects_Front: '',
					languages_Front: {},
					moreLanguages_Front: '',
					frameworks_Front: '',
					figma_Front: '',
					trends_Front: '',
					futureProjects_Front: '',
					work_Front: '',
					tryhard_Front: '',
			  }
	})

	const handleChange = e => {
		const { name, value, scrollHeight, clientHeight } = e.target
		const uniqueId = e.target.id
		if (scrollHeight > clientHeight) {
			e.target.rows += 1
		}
		if (e.target.type === 'radio') {
			setAnswersForm(prevState => ({
				...prevState,
				languages_Front: {
					...prevState.languages_Front,
					[name]: value,
				},
			}))
		} else {
			setAnswersForm(prevState => ({
				...prevState,
				[name]: value,
			}))
		}
		// Обновите количество строк в каждой textarea
		setTextAreaRows2(prevState => ({
			...prevState,
			[uniqueId]: e.target.rows,
		}))
		localStorage.setItem(
			'textareaRows2',
			JSON.stringify({ ...textareaRows2, [uniqueId]: e.target.rows })
		)
		localStorage.setItem(
			'answersFormFront',
			JSON.stringify({
				...answersForm,
				[name]: value,
			})
		)
	}

	useEffect(() => {
		const savedRows = localStorage.getItem('textareaRows2')
		if (savedRows) {
			const textareas = document.querySelectorAll('.dynamic-textarea')
			textareas.forEach(textarea => {
				const name = textarea.getAttribute('name')
				textarea.rows = parseInt(JSON.parse(savedRows)[name] || 1)
			})
		}
	}, [])

	useEffect(() => {
		const storedAnswers = localStorage.getItem('answersFormFront')
		if (storedAnswers) {
			setAnswersForm(JSON.parse(storedAnswers))
		}
	}, [])

	const onSubmit = async () => {
		const emptyFields = Object.keys(answersForm).filter(
			key =>
				typeof answersForm[key] === 'string' && answersForm[key].trim() === ''
		)
		if (emptyFields.length > 0) {
			setError('Заполните все поля')
			setIsFormIncomplete(true)
			return
		}

		setIsFormIncomplete(false)
		const frontData = {
			experience: answersForm.projects_Front,
			html_lang: parseInt(answersForm.languages_Front?.html || 0),
			css_lang: parseInt(answersForm.languages_Front?.css || 0),
			js_lang: parseInt(answersForm.languages_Front?.js || 0),
			react_lang: parseInt(answersForm.languages_Front?.react || 0),
			git_lang: parseInt(answersForm.languages_Front?.git || 0),
			npm_lang: parseInt(answersForm.languages_Front?.npm || 0),
			other_languages: answersForm.moreLanguages_Front,
			new_experience: answersForm.frameworks_Front,
			projects: answersForm.futureProjects_Front,
			pros_cons: answersForm.work_Front,
			difficult_project: answersForm.figma_Front,
			difficulties: answersForm.trends_Front,
			time: answersForm.tryhard_Front,
		}

		await handleSubmit(frontData)
	}

	return (
		<>
			<div className='Form Form_Frontend'>
				<form
					onSubmit={e => {
						e.preventDefault()
						onSubmit()
					}}
					className='survival-school-form'
				>
					<h2 className='p_Front'>Вопросы на Frontend </h2>

					<div>
						<label htmlFor='projects_Front'>
							Расскажи об опыте разработки с стороны frontend. Какие проекты
							были тобой реализованы? В чём заключалась их суть? (Если есть,
							прикрепи ссылку на Git или файлообменник)
						</label>
						<textarea
							id='projects_Front'
							name='projects_Front'
							value={answersForm.projects_Front}
							onChange={handleChange}
							rows={4}
							style={{ resize: 'none' }}
						/>
					</div>
					<div>
						<label htmlFor='languages_Front'>
							Оцени знания основных языков и инструментов:
						</label>
						<table>
							<tr>
								<th style={{ fontSize: '14px' }}>Язык / Инструмент</th>
								<th>0</th>
								<th>1</th>
								<th>2</th>
								<th>3</th>
								<th>4</th>
								<th>5</th>
							</tr>
							<tr>
								<td>HTML</td>
								<td>
									<input
										type='radio'
										id='html_0'
										name='html'
										value='0'
										onChange={handleChange}
									/>
								</td>
								<td>
									<input
										type='radio'
										id='html_1'
										name='html'
										value='1'
										onChange={handleChange}
									/>
								</td>
								<td>
									<input
										type='radio'
										id='html_2'
										name='html'
										value='2'
										onChange={handleChange}
									/>
								</td>
								<td>
									<input
										type='radio'
										id='html_3'
										name='html'
										value='3'
										onChange={handleChange}
									/>
								</td>
								<td>
									<input
										type='radio'
										id='html_4'
										name='html'
										value='4'
										onChange={handleChange}
									/>
								</td>
								<td>
									<input
										type='radio'
										id='html_5'
										name='html'
										value='5'
										onChange={handleChange}
									/>
								</td>
							</tr>
							<tr>
								<td>CSS</td>
								<td>
									<input
										type='radio'
										id='css_0'
										name='css'
										value='0'
										onChange={handleChange}
									/>
								</td>
								<td>
									<input
										type='radio'
										id='css_1'
										name='css'
										value='1'
										onChange={handleChange}
									/>
								</td>
								<td>
									<input
										type='radio'
										id='css_2'
										name='css'
										value='2'
										onChange={handleChange}
									/>
								</td>
								<td>
									<input
										type='radio'
										id='css_3'
										name='css'
										value='3'
										onChange={handleChange}
									/>
								</td>
								<td>
									<input
										type='radio'
										id='css_4'
										name='css'
										value='4'
										onChange={handleChange}
									/>
								</td>
								<td>
									<input
										type='radio'
										id='css_5'
										name='css'
										value='5'
										onChange={handleChange}
									/>
								</td>
							</tr>
							<tr>
								<td>JavaScript</td>
								<td>
									<input
										type='radio'
										id='javascript_0'
										name='javascript'
										value='0'
										onChange={handleChange}
									/>
								</td>
								<td>
									<input
										type='radio'
										id='javascript_1'
										name='javascript'
										value='1'
										onChange={handleChange}
									/>
								</td>
								<td>
									<input
										type='radio'
										id='javascript_2'
										name='javascript'
										value='2'
										onChange={handleChange}
									/>
								</td>
								<td>
									<input
										type='radio'
										id='javascript_3'
										name='javascript'
										value='3'
										onChange={handleChange}
									/>
								</td>
								<td>
									<input
										type='radio'
										id='javascript_4'
										name='javascript'
										value='4'
										onChange={handleChange}
									/>
								</td>
								<td>
									<input
										type='radio'
										id='javascript_5'
										name='javascript'
										value='5'
										onChange={handleChange}
									/>
								</td>
							</tr>
							<tr>
								<td>React</td>
								<td>
									<input
										type='radio'
										id='react_0'
										name='react'
										value='0'
										onChange={handleChange}
									/>
								</td>
								<td>
									<input
										type='radio'
										id='react_1'
										name='react'
										value='1'
										onChange={handleChange}
									/>
								</td>
								<td>
									<input
										type='radio'
										id='react_2'
										name='react'
										value='2'
										onChange={handleChange}
									/>
								</td>
								<td>
									<input
										type='radio'
										id='react_3'
										name='react'
										value='3'
										onChange={handleChange}
									/>
								</td>
								<td>
									<input
										type='radio'
										id='react_4'
										name='react'
										value='4'
										onChange={handleChange}
									/>
								</td>
								<td>
									<input
										type='radio'
										id='react_5'
										name='react'
										value='5'
										onChange={handleChange}
									/>
								</td>
							</tr>
							<tr>
								<td>Git</td>
								<td>
									<input
										type='radio'
										id='git_0'
										name='git'
										value='0'
										onChange={handleChange}
									/>
								</td>
								<td>
									<input
										type='radio'
										id='git_1'
										name='git'
										value='1'
										onChange={handleChange}
									/>
								</td>
								<td>
									<input
										type='radio'
										id='git_2'
										name='git'
										value='2'
										onChange={handleChange}
									/>
								</td>
								<td>
									<input
										type='radio'
										id='git_3'
										name='git'
										value='3'
										onChange={handleChange}
									/>
								</td>
								<td>
									<input
										type='radio'
										id='git_4'
										name='git'
										value='4'
										onChange={handleChange}
									/>
								</td>
								<td>
									<input
										type='radio'
										id='git_5'
										name='git'
										value='5'
										onChange={handleChange}
									/>
								</td>
							</tr>
							<tr>
								<td>NPM</td>
								<td>
									<input
										type='radio'
										id='npm_0'
										name='npm'
										value='0'
										onChange={handleChange}
									/>
								</td>
								<td>
									<input
										type='radio'
										id='npm_1'
										name='npm'
										value='1'
										onChange={handleChange}
									/>
								</td>
								<td>
									<input
										type='radio'
										id='npm_2'
										name='npm'
										value='2'
										onChange={handleChange}
									/>
								</td>
								<td>
									<input
										type='radio'
										id='npm_3'
										name='npm'
										value='3'
										onChange={handleChange}
									/>
								</td>
								<td>
									<input
										type='radio'
										id='npm_4'
										name='npm'
										value='4'
										onChange={handleChange}
									/>
								</td>
								<td>
									<input
										type='radio'
										id='npm_5'
										name='npm'
										value='5'
										onChange={handleChange}
									/>
								</td>
							</tr>
						</table>
					</div>
					<div>
						<label htmlFor='moreLanguages_Front'>
							Укажи иные языки/инструменты, которыми ты пользовался и уровень
							владения ими если такие имеются. (пример: Typescript:4)
						</label>
						<textarea
							id='moreLanguages_Front'
							name='moreLanguages_Front'
							value={answersForm.moreLanguages_Front}
							onChange={handleChange}
							rows={4}
							style={{ resize: 'none' }}
						/>
					</div>
					<div>
						<label htmlFor='frameworks_Front'>
							С какими фреймворками и библиотеками ты имел(а) дело?
						</label>
						<textarea
							id='frameworks_Front'
							name='frameworks_Front'
							value={answersForm.frameworks_Front}
							onChange={handleChange}
							rows={4}
							style={{ resize: 'none' }}
						/>
					</div>
					<div>
						<label htmlFor='figma_Front'>Работал(а) ли ты с figma?</label>
						<textarea
							id='figma_Front'
							name='figma_Front'
							value={answersForm.figma_Front}
							onChange={handleChange}
							rows={4}
							style={{ resize: 'none' }}
						/>
					</div>
					<div>
						<label htmlFor='trends_Front'>
							Что бы ты хотел изучить нового или какие навыки развить?
						</label>
						<textarea
							id='trends_Front'
							name='trends_Front'
							value={answersForm.trends_Front}
							onChange={handleChange}
							rows={4}
							style={{ resize: 'none' }}
						/>
					</div>
					<div>
						<label htmlFor='futureProjects_Front'>
							Какие бы проекты ты хотел реализовать и в чём бы была их суть?
						</label>
						<textarea
							id='futureProjects_Front'
							name='futureProjects_Front'
							value={answersForm.futureProjects_Front}
							onChange={handleChange}
							rows={4}
							style={{ resize: 'none' }}
						/>
					</div>
					<div>
						<label htmlFor='work_Front'>
							Расскажи про свои сильные и слабые стороны в работе
						</label>
						<textarea
							id='work_Front'
							name='work_Front'
							value={answersForm.work_Front}
							onChange={handleChange}
							rows={4}
							style={{ resize: 'none' }}
						/>
					</div>
					<div>
						<label htmlFor='tryhard_Front'>
							Сколько времени ты готов посвящать работе над проектами в рамках
							ITC?
						</label>
						<textarea
							id='tryhard_Front'
							name='tryhard_Front'
							value={answersForm.tryhard_Front}
							onChange={handleChange}
							rows={4}
							style={{ resize: 'none' }}
						/>
					</div>
					<div style={{ color: 'red' }}>{isFormIncomplete && error}</div>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<button
							className='form__button frontButton'
							onClick={handleBack}
							type='button'
						>
							Назад
						</button>
						<button className='form__button frontButton' type='submit'>
							{currentStep === selectedStructures.length - 1
								? 'Отправить'
								: 'Далее'}
						</button>
					</div>
				</form>
				<div className='running-text-container' id='running-text-container'>
					<pre className='text'>
						<code>ITC СНОВА СДЕЛАЛ КРАСИВО</code>
					</pre>
				</div>
			</div>
		</>
	)
}

export default FormFront
