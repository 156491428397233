import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Modal from '../Modal/Modal'
import FormBack from './FormBack'
import FormFront from './FormFront'
import FormMedia from './FormMedia'
import FormProject from './FormProject'

const Form = ({ sendDataToBackend }) => {
	const [currentStep, setCurrentStep] = useState(0)
	const mainFormAnswers = JSON.parse(localStorage.getItem('answers'))
	const [selectedStructures] = useState(mainFormAnswers.structure)
	const navigate = useNavigate()

	// Инициализируем formData из localStorage
	const [formData, setFormData] = useState(() => {
		const savedFormData = localStorage.getItem('structureFormData')
		return savedFormData ? JSON.parse(savedFormData) : {}
	})

	const [isModalOpen, setIsModalOpen] = useState(false)

	const handleStructureData = (structure, data) => {
		return new Promise(resolve => {
			setFormData(prev => {
				const newData = {
					...prev,
					[structure]: data,
				}
				// Сохраняем в localStorage при каждом обновлении
				localStorage.setItem('structureFormData', JSON.stringify(newData))
				console.log('Сохранены данные структуры:', {
					структура: structure,
					данные: data,
					общий_объект: newData,
				})
				resolve(newData)
				return newData
			})
		})
	}

	const handleNext = () => {
		if (!selectedStructures?.length) return
		if (currentStep < selectedStructures.length - 1) {
			setCurrentStep(currentStep + 1)
			window.scrollTo(0, 0)
		}
	}

	const handleBack = () => {
		if (currentStep === 0) {
			navigate('/questions')
		} else {
			setCurrentStep(currentStep - 1)
		}
		window.scrollTo(0, 0)
	}

	const sendFormData = async () => {
		try {
			const baseAnswers = JSON.parse(localStorage.getItem('answers'))
			const savedStructureData = localStorage.getItem('structureFormData')
			const structureData = savedStructureData
				? JSON.parse(savedStructureData)
				: {}

			// Проверяем, что все выбранные структуры имеют данные
			const missingStructures = selectedStructures.filter(
				structure => !structureData[structure.toLowerCase()]
			)

			if (missingStructures.length > 0) {
				throw new Error(
					`Не заполнены данные для структур: ${missingStructures.join(', ')}`
				)
			}

			const fullData = {
				base_questions: {
					name: baseAnswers.name,
					faculty: baseAnswers.faculty,
					grade: baseAnswers.grade,
					vk: baseAnswers.vk,
					tg: baseAnswers.tg,
					phone_number: baseAnswers.phone_number,
					motivation: baseAnswers.motivation,
					knowledge: baseAnswers.knowledge,
					project_str: baseAnswers.structure.includes('Project'),
					back_str: baseAnswers.structure.includes('Backend'),
					front_str: baseAnswers.structure.includes('Frontend'),
					media_str: baseAnswers.structure.includes('Media'),
				},
				...structureData,
			}

			console.log('Отправка данных на сервер:', {
				основные_вопросы: fullData.base_questions,
				данные_структур: structureData,
			})

			const response = await fetch(
				'https://valentine-staging-back.itc-hub.ru/active-form',
				{
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify(fullData),
				}
			)

			if (!response.ok) {
				throw new Error(`Ошибка отправки: ${response.status}`)
			}

			console.log('Ответ сервера:', response.status)
			localStorage.removeItem('structureFormData')
			setIsModalOpen(true)
			/*setTimeout(() => {
				navigate('/')
			}, 2000)*/
		} catch (error) {
			console.error('Ошибка при отправке данных:', error)
			alert(error.message || 'Произошла ошибка при отправке формы')
		}
	}

	const handleSubmit = async (formData, structure) => {
		try {
			await handleStructureData(structure.toLowerCase(), formData)
			if (currentStep === selectedStructures.length - 1) {
				await sendFormData()
			} else {
				handleNext()
			}
		} catch (error) {
			console.error('Ошибка при сохранении данных:', error)
			alert('Произошла ошибка при сохранении данных формы')
		}
	}

	const closeModal = () => {
		setIsModalOpen(false)
		navigate('/')
	}

	const renderForm = () => {
		switch (selectedStructures[currentStep]) {
			case 'Backend':
				return (
					<FormBack
						handleNext={handleNext}
						handleBack={handleBack}
						handleSubmit={data => handleSubmit(data, 'Backend')}
						currentStep={currentStep}
						selectedStructures={selectedStructures}
					/>
				)
			case 'Frontend':
				return (
					<FormFront
						handleNext={handleNext}
						handleBack={handleBack}
						handleSubmit={data => handleSubmit(data, 'Frontend')}
						currentStep={currentStep}
						selectedStructures={selectedStructures}
					/>
				)
			case 'Media':
				return (
					<FormMedia
						handleNext={handleNext}
						handleBack={handleBack}
						handleSubmit={data => handleSubmit(data, 'Media')}
						currentStep={currentStep}
						selectedStructures={selectedStructures}
					/>
				)
			case 'Project':
				return (
					<FormProject
						handleNext={handleNext}
						handleBack={handleBack}
						handleSubmit={data => handleSubmit(data, 'Project')}
						currentStep={currentStep}
						selectedStructures={selectedStructures}
					/>
				)
			default:
				return null
		}
	}

	return (
		<div>
			{renderForm()}
			{isModalOpen && (
				<Modal
					message='Анкета успешно отправлена! Спасибо за участие!'
					onClose={closeModal}
				/>
			)}
		</div>
	)
}

export default Form
