import React from 'react'
import './Modal.css' // Импортируем стили для модального окна

const Modal = ({ message, onClose }) => {
	return (
		<div className='modal-overlay'>
			<div className='modal-content'>
				<h2>{message}</h2>
				<button onClick={onClose}>Готово</button>
			</div>
		</div>
	)
}

export default Modal
