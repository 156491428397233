import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Modal from '../Modal/Modal'
import MentoringFormBack from './MentoringFormBack'
import MentoringFormFront from './MentoringFormFront'
import MentoringFormProject from './MentoringFormProject'

const MentoringForm = ({ sendDataToBackend }) => {
	const navigate = useNavigate()
	const [currentStep, setCurrentStep] = useState(0)
	const mainFormAnswers = JSON.parse(localStorage.getItem('answers')) || {
		structure: [],
	}
	const [selectedStructures] = useState(
		mainFormAnswers.structure.filter(structure =>
			['Backend', 'Frontend', 'Project'].includes(structure)
		) || []
	)
	const [formData, setFormData] = useState(() => {
		const savedFormData = localStorage.getItem('mentoringFormData')
		return savedFormData ? JSON.parse(savedFormData) : {}
	})
	const [isModalOpen, setIsModalOpen] = useState(false)

	const handleStructureData = (structure, data) => {
		return new Promise(resolve => {
			setFormData(prev => {
				const newData = {
					...prev,
					[structure]: data,
				}
				localStorage.setItem('mentoringFormData', JSON.stringify(newData))
				resolve(newData)
				return newData
			})
		})
	}

	const handleSubmit = async (formData, structure) => {
		try {
			await handleStructureData(structure.toLowerCase(), formData)
			if (currentStep === selectedStructures.length - 1) {
				await sendFormData()
			} else {
				handleNext()
			}
		} catch (error) {
			console.error('Ошибка при сохранении данных:', error)
			alert('Произошла ошибка при сохранении данных формы')
		}
	}

	const sendFormData = async () => {
		try {
			const baseAnswers = JSON.parse(localStorage.getItem('answers'))
			const savedFormData = localStorage.getItem('mentoringFormData')
			const structureData = savedFormData ? JSON.parse(savedFormData) : {}

			const fullData = {
				base_questions: {
					name: baseAnswers.name,
					faculty: baseAnswers.faculty,
					grade: baseAnswers.grade,
					vk: baseAnswers.vk,
					tg: baseAnswers.tg,
					phone_number: baseAnswers.phone_number,
					motivation: baseAnswers.motivation,
					knowledge: baseAnswers.knowledge,
					project_mentor: baseAnswers.structure.includes('Project'),
					back_mentor: baseAnswers.structure.includes('Backend'),
					front_mentor: baseAnswers.structure.includes('Frontend'),
				},
				project: structureData.project || {},
				backend: structureData.backend || {},
				frontend: structureData.frontend || {},
			}

			const response = await fetch(
				'https://valentine-staging-back.itc-hub.ru/mentor-form',
				{
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify(fullData),
				}
			)

			if (!response.ok) {
				throw new Error(`Ошибка отправки: ${response.status}`)
			}

			localStorage.removeItem('mentoringFormData')
			setIsModalOpen(true)
			/*setTimeout(() => {
				navigate('/')
			}, 2000)*/
		} catch (error) {
			console.error('Ошибка при отправке данных:', error)
			alert(error.message || 'Произошла ошибка при отправке формы')
		}
	}

	const handleNext = () => {
		if (!selectedStructures?.length) return
		if (currentStep < selectedStructures.length - 1) {
			setCurrentStep(currentStep + 1)
			window.scrollTo(0, 0)
		}
	}

	const handleBack = () => {
		if (currentStep === 0) {
			navigate('/mentoring')
			window.scrollTo(0, 0)
		} else {
			setCurrentStep(currentStep - 1)
			window.scrollTo(0, 0)
		}
	}

	const closeModal = () => {
		setIsModalOpen(false)
		navigate('/')
	}

	const renderForm = () => {
		if (!selectedStructures || selectedStructures.length === 0) {
			return <div>Выберите структуры в предыдущем шаге</div>
		}

		const isLastStep = currentStep === selectedStructures.length - 1

		if (currentStep >= selectedStructures.length) {
			return <div>Ошибка: неверный шаг</div>
		}

		switch (selectedStructures[currentStep]) {
			case 'Backend':
				return (
					<MentoringFormBack
						handleNext={handleNext}
						handleBack={handleBack}
						handleSubmit={data => handleSubmit(data, 'Backend')}
						currentStep={currentStep}
						selectedStructures={selectedStructures}
					/>
				)
			case 'Frontend':
				return (
					<MentoringFormFront
						handleNext={handleNext}
						handleBack={handleBack}
						handleSubmit={data => handleSubmit(data, 'Frontend')}
						currentStep={currentStep}
						selectedStructures={selectedStructures}
					/>
				)
			case 'Project':
				return (
					<MentoringFormProject
						handleNext={handleNext}
						handleBack={handleBack}
						handleSubmit={data => handleSubmit(data, 'Project')}
						currentStep={currentStep}
						selectedStructures={selectedStructures}
					/>
				)
			default:
				return null
		}
	}

	return (
		<div>
			{renderForm()}
			{isModalOpen && (
				<Modal
					message='Анкета успешно отправлена! Спасибо за участие!'
					onClose={closeModal}
				/>
			)}
		</div>
	)
}

export default MentoringForm
