import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import back from '../../images/Group 4.png'

const MainQuestions = ({ goForm, handleSelectQuestions }) => {
	const [answers, setAnswers] = useState({
		name: '',
		faculty: '',
		grade: '',
		vk: '',
		tg: '',
		phone_number: '',
		motivation: '',
		knowledge: '',
		structure: [],
	})

	const [errors, setErrors] = useState({})

	const [textareaRows1, setTextAreaRows1] = useState({})

	useEffect(() => {
		const storedAnswers = localStorage.getItem('answers')
		if (storedAnswers) {
			setAnswers(JSON.parse(storedAnswers))
		}
	}, [])

	useEffect(() => {
		const savedRows = localStorage.getItem('textareaRows1')
		if (savedRows) {
			setTextAreaRows1(JSON.parse(savedRows))
		}
	}, [])

	const handleAnswerChange = event => {
		const { name, value } = event.target
		setAnswers(prevState => ({
			...prevState,
			[name]: value,
		}))
		setErrors(prevState => ({
			...prevState,
			[name]: '',
		}))
	}

	const handleTextAreaChange = event => {
		const { name, value, scrollHeight, clientHeight } = event.target
		setAnswers(prevState => ({
			...prevState,
			[name]: value,
		}))
		setErrors(prevState => ({
			...prevState,
			[name]: '',
		}))

		if (scrollHeight > clientHeight) {
			event.target.rows += 1
			setTextAreaRows1(prevState => ({
				...prevState,
				[name]: event.target.rows,
			}))
			localStorage.setItem(
				'textareaRows1',
				JSON.stringify({
					...textareaRows1,
					[name]: event.target.rows,
				})
			)
		}
	}

	useEffect(() => {
		const savedRows = localStorage.getItem('textareaRows1')
		if (savedRows) {
			setTextAreaRows1(JSON.parse(savedRows))
		}
	}, [])

	const handleCheckboxChange = event => {
		const { name, value, checked } = event.target

		setAnswers(prevAnswers => {
			const updatedAnswers = { ...prevAnswers }
			const selectedStructures = updatedAnswers.structure || []

			if (checked) {
				selectedStructures.push(value)
			} else {
				const index = selectedStructures.indexOf(value)
				if (index !== -1) {
					selectedStructures.splice(index, 1)
				}
			}

			updatedAnswers.structure = selectedStructures
			return updatedAnswers
		})
	}
	const navigate = useNavigate()
	const handleBackMain = () => {
		navigate('/')
		window.scrollTo(0, 0)
	}

	const validateVKLink = link => {
		return (
			link.startsWith('https://vk.com/') ||
			link.startsWith('http://vk.com/') ||
			link.startsWith('vk.com/') ||
			link.startsWith('https://m.vk.com/') ||
			link.startsWith('m.vk.com/') ||
			link.startsWith('https://vk.ru/') ||
			link.startsWith('http://vk.ru/') ||
			link.startsWith('vk.ru/') ||
			link.startsWith('https://m.vk.ru/') ||
			link.startsWith('m.vk.ru/')
		)
	}

	const validatePhoneNumber = number => {
		return /^\+7\d{10}$/.test(number)
	}

	const handleSubmit = () => {
		const errors = {}
		if (answers.name.trim() === '') {
			errors.name = 'Заполните ФИО'
		}
		if (answers.faculty.trim() === '') {
			errors.faculty = 'Заполните высшую школу/факультет/институт'
		}
		if (answers.grade.trim() === '') {
			errors.grade = 'Выберите курс'
		}
		if (!validateVKLink(answers.vk.trim())) {
			errors.vk = 'Введите ссылку на ВК'
		}
		if (answers.tg.trim() === '') {
			errors.tg = 'Введите ник в ТГ'
		}
		if (!validatePhoneNumber(answers.phone_number.trim())) {
			errors.phone_number = 'Введите корректный номер телефона (+7XXXXXXXXXX)'
		}
		if (answers.motivation.trim() === '') {
			errors.motivation = 'Заполни это поле'
		}
		if (answers.ITC.trim() === '') {
			errors.ITC = 'Заполни это поле'
		}
		if (answers.structure.length === 0) {
			errors.structure = 'Выбери хотя бы одну структуру'
		}
		setErrors(errors)

		if (Object.keys(errors).length === 0) {
			localStorage.setItem('answers', JSON.stringify(answers))
			handleSelectQuestions(answers)
			goForm(answers)
		}
	}

	return (
		<div className='questions'>
			<img src={back} className='background' />
			<div className='questions-container'>
				<h2 className='questions-title'>АНКЕТА АКТИВ</h2>
				<div className='block'>
					<div className='question-block'>
						<label className='question-label'>ФИО:</label>
						<input
							type='text'
							name='name'
							value={answers.name}
							onChange={handleAnswerChange}
							autoComplete='off'
							className={`question-input ${errors.name && 'error-input'}`}
						/>
						{errors.name && <p className='error-message'>{errors.name}</p>}
					</div>
					<div className='question-block'>
						<label className='question-label'>
							Твоя высшая школа/факультет/институт
						</label>
						<select
							name='faculty'
							value={answers.faculty}
							onChange={handleAnswerChange}
							className={`question-input ${errors.faculty && 'error-input'}`}
						>
							<option value=''>Выберите факультет</option>
							<option value='ВШКМиС'>ВШКМиС</option>
							<option value='ВИШНМиТ'>ВИШНМиТ</option>
							<option value='ВШКИ'>ВШКИ</option>
							<option value='ВШМ'>ВШМ</option>
							<option value='ВШП'>ВШП</option>
							<option value='ВШСГН'>ВШСГН</option>
							<option value='ВШФ'>ВШФ</option>
							<option value='ВШЭиБ'>ВШЭиБ</option>
							<option value='ИПАМ'>ИПАМ</option>
							<option value='Форсайт'>Форсайт</option>
							<option value='Интеграл'>Интеграл</option>
							<option value='Капитаны'>Капитаны</option>
						</select>
						{errors.faculty && (
							<p className='error-message'>{errors.faculty}</p>
						)}
					</div>
					<div className='question-block'>
						<label className='question-label'>Курс:</label>
						<select
							name='grade'
							value={answers.grade}
							onChange={handleAnswerChange}
							className={`question-input ${errors.grade && 'error-input'}`}
						>
							<option value=''>Выберите курс</option>
							<option value='1'>1</option>
							<option value='2'>2</option>
							<option value='3'>3</option>
							<option value='4'>4</option>
							<option value='5'>5</option>
							<option value='1м'>1м</option>
							<option value='2м'>2м</option>
						</select>
						{errors.grade && <p className='error-message'>{errors.grade}</p>}
					</div>

					<div className='question-block'>
						<label className='question-label'>Ссылка в ВК:</label>
						<input
							type='text'
							name='vk'
							placeholder='https://vk.com/....'
							value={answers.vk}
							onChange={handleAnswerChange}
							className={`question-input ${errors.vk && 'error-input'}`}
						/>
						{errors.vk && <p className='error-message'>{errors.vk}</p>}
					</div>

					<div className='question-block'>
						<label className='question-label'>Ссылка на ТГ или никнейм:</label>
						<input
							type='text'
							name='tg'
							value={answers.tg}
							onChange={handleAnswerChange}
							className={`question-input ${errors.tg && 'error-input'}`}
						/>
						{errors.tg && <p className='error-message'>{errors.tg}</p>}
					</div>

					<div className='question-block'>
						<label className='question-label'>Твой номер телефона:</label>
						<input
							type='text'
							name='phone_number'
							placeholder='+7..........'
							value={answers.phone_number}
							onChange={handleAnswerChange}
							className={`question-input ${
								errors.phone_number && 'error-input'
							}`}
						/>
						{errors.phone_number && (
							<p className='error-message'>{errors.phone_number}</p>
						)}
					</div>
					<div className='question-block'>
						<label className='question-label'>
							Опиши свою мотивацию и почему ты хочешь стать частью ITC
						</label>
						<textarea
							type='text'
							name='motivation'
							value={answers.motivation}
							rows={textareaRows1['motivation'] || 1} // Используем количество строк из состояния
							style={{ resize: 'none' }}
							onChange={handleTextAreaChange} // Используем новый обработчик
							className={`dynamic-textarea question-input ${
								errors.motivation && 'error-input'
							}`}
						/>
						{errors.motivation && (
							<p className='error-message'>{errors.motivation}</p>
						)}
					</div>

					<div className='question-block'>
						<label className='question-label'>
							Расскажи, что ты знаешь об ITC? О каких продуктах ИТС ты слышал,
							какими пользовался? Как бы ты их улучшил?
						</label>
						<textarea
							type='text'
							name='ITC'
							value={answers.ITC}
							rows={textareaRows1['ITC'] || 1} // Используем количество строк из состояния
							style={{ resize: 'none' }}
							onChange={handleTextAreaChange}
							className={`dynamic-textarea question-input ${
								errors.ITC && 'error-input'
							}`}
						/>
						{errors.ITC && <p className='error-message'>{errors.ITC}</p>}
					</div>
					<div className='question-block'>
						<label className='question-label'>
							В какую структуру хочешь попасть?
						</label>
						<div className='checkboxes'>
							<label>
								<input
									style={{ backgroundColor: '#00eb7a' }}
									type='checkbox'
									name='structure'
									value='Backend'
									checked={answers.structure.includes('Backend')}
									onChange={handleCheckboxChange}
									className={`question-checkbox custom-checkbox1  ${
										errors.structure && 'error-input'
									}`}
								/>
								Backend
							</label>
							<label>
								<input
									style={{ backgroundColor: '#00eb7a' }}
									type='checkbox'
									name='structure'
									value='Frontend'
									checked={answers.structure.includes('Frontend')}
									onChange={handleCheckboxChange}
									className={`question-checkbox custom-checkbox1 ${
										errors.structure && 'error-input'
									}`}
								/>
								Frontend
							</label>
							<label>
								<input
									style={{ backgroundColor: '#00eb7a' }}
									type='checkbox'
									name='structure'
									value='Media'
									checked={answers.structure.includes('Media')}
									onChange={handleCheckboxChange}
									className={`question-checkbox custom-checkbox1 ${
										errors.structure && 'error-input'
									}`}
								/>
								Media
							</label>
							<label>
								<input
									style={{ backgroundColor: '#00eb7a' }}
									type='checkbox'
									name='structure'
									value='Project'
									checked={answers.structure.includes('Project')}
									onChange={handleCheckboxChange}
									className={`question-checkbox custom-checkbox1 ${
										errors.structure && 'error-input'
									}`}
								/>
								Project
							</label>
						</div>
						{errors.structure && (
							<p className='error-message'>{errors.structure}</p>
						)}
					</div>
				</div>
				<div className='navButtons'>
					<button onClick={handleBackMain} className='form__button'>
						Назад
					</button>
					<button onClick={handleSubmit} className='form__button'>
						Далее
					</button>
				</div>
			</div>
		</div>
	)
}

export default MainQuestions
