import React, { useEffect, useState } from 'react'
// Получаем все элементы input и textarea на страниц
const FormBack = ({
	handleNext,
	handleBack,
	handleSubmit,
	currentStep,
	selectedStructures,
}) => {
	const [submited, setSubmited] = useState(false)
	const [error, setError] = useState('')
	const mainFormAnswers = JSON.parse(localStorage.getItem('answers'))
	const [textareaRows, setTextAreaRows] = useState({})

	const [showSubmitButton, setShowSubmitButton] = useState(false) // Добавляем состояние для отображения кнопки "Отправить"

	useEffect(() => {
		if (currentStep === selectedStructures.length - 1) {
			setShowSubmitButton(true)
		} else {
			setShowSubmitButton(false)
		}
	}, [currentStep, selectedStructures])

	const [answersForm, setAnswersForm] = useState(() => {
		const storedAnswers = localStorage.getItem('answersFormBack')
		return storedAnswers
			? JSON.parse(storedAnswers)
			: {
					experience_Back: '',
					reasonsWhy_Back: '',
					languages_Back: {},
					moreLanguages_Back: '',
					future_Back: '',
					sides_Back: '',
					teamwork_Back: '',
					tryhard_Back: '',
			  }
	})
	const [isFormIncomplete, setIsFormIncomplete] = useState(false)

	const handleChange = e => {
		const { name, value, scrollHeight, clientHeight } = e.target
		const uniqueId = e.target.id

		if (scrollHeight > clientHeight) {
			e.target.rows += 1
		}
		// Обновите состояние каждой textarea, используя уникальный идентификатор
		if (e.target.type === 'radio') {
			setAnswersForm(prevState => ({
				...prevState,
				languages_Back: {
					...prevState.languages_Back,
					[name]: value,
				},
			}))
		} else {
			setAnswersForm(prevState => ({
				...prevState,
				[name]: value,
			}))
		}

		// Обновите количество строк в каждой textarea
		setTextAreaRows(prevState => ({
			...prevState,
			[uniqueId]: e.target.rows,
		}))

		// localStorage.setItem("textareaRows", JSON.stringify(textAreaRows));
		localStorage.setItem(
			'textareaRows',
			JSON.stringify({ ...textareaRows, [uniqueId]: e.target.rows })
		)
		localStorage.setItem(
			'answersFormBack',
			JSON.stringify({
				...answersForm,
				[name]: value,
			})
		)
	}

	useEffect(() => {
		const savedRows = localStorage.getItem('textareaRows')
		if (savedRows) {
			const textareas = document.querySelectorAll('.dynamic-textarea')
			textareas.forEach(textarea => {
				const name = textarea.getAttribute('name')
				textarea.rows = parseInt(JSON.parse(savedRows)[name] || 1)
			})
		}
	}, [])

	useEffect(() => {
		const storedAnswers = localStorage.getItem('answersFormBack')
		if (storedAnswers) {
			setAnswersForm(JSON.parse(storedAnswers))
		}
	}, [])

	const onSubmit = async () => {
		const emptyFields = Object.keys(answersForm).filter(
			key =>
				typeof answersForm[key] === 'string' && answersForm[key].trim() === ''
		)
		if (emptyFields.length > 0) {
			setError('Заполните все поля')
			setIsFormIncomplete(true)
			return
		}

		setIsFormIncomplete(false)
		const backData = {
			experience: answersForm.experience_Back,
			motivation: answersForm.reasonsWhy_Back,
			python_lang: parseInt(answersForm.languages_Back?.python || 0),
			django_lang: parseInt(answersForm.languages_Back?.django || 0),
			celery_lang: parseInt(answersForm.languages_Back?.celery || 0),
			git_lang: parseInt(answersForm.languages_Back?.git || 0),
			unittest_lang: parseInt(answersForm.languages_Back?.unittest || 0),
			signals_lang: parseInt(answersForm.languages_Back?.signals || 0),
			commands_lang: parseInt(answersForm.languages_Back?.commands || 0),
			aiogram_lang: parseInt(answersForm.languages_Back?.aiogram || 0),
			telebot_lang: parseInt(answersForm.languages_Back?.telebot || 0),
			vkbottle_lang: parseInt(answersForm.languages_Back?.vkbottle || 0),
			other_languages: answersForm.moreLanguages_Back,
			dream_project: answersForm.future_Back,
			pros_cons: answersForm.sides_Back,
			difficulties: answersForm.teamwork_Back,
			time: answersForm.tryhard_Back,
		}

		await handleSubmit(backData)
	}

	return (
		<>
			<div className='Form Form_Backend'>
				<form
					onSubmit={e => {
						e.preventDefault()
						onSubmit()
					}}
					className='survival-school-form'
				>
					<h2 className='p_Back'>Вопросы на Backend</h2>

					<div>
						<label htmlFor='experience_Back'>
							Был ли у тебя опыт разработки backend части продуктов? Если да, то
							опиши проекты, которые ты делал(а), их суть, а также прикрепи
							ссылку на GitHub/файлообменник с проектами (если есть)
						</label>
						<textarea
							id='experience_Back'
							name='experience_Back'
							className='dynamic-textarea'
							value={answersForm.experience_Back}
							onChange={handleChange}
							rows={textareaRows['experience_Back'] || 1}
							style={{ resize: 'none' }}
						/>
					</div>
					<div>
						<label htmlFor='reasonsWhy_Back'>
							Почему ты хочешь попасть именно в эту структуру?
						</label>
						<textarea
							id='reasonsWhy_Back'
							name='reasonsWhy_Back'
							className='dynamic-textarea'
							value={answersForm.reasonsWhy_Back}
							onChange={handleChange}
							rows={textareaRows['reasonsWhy_Back'] || 1}
							style={{ resize: 'none' }}
						/>
					</div>
					<div>
						<label htmlFor='languages_Back'>
							Укажи уровень владения нижеперечисленными языками
							программирования/фреймворками/инструментами (где 0 - вообще не
							знаю, 5 - знаю идеально)
						</label>

						<table>
							<tr>
								<th className='th_back'>Язык</th>
								<th className='th_back'>0</th>
								<th className='th_back'>1</th>
								<th className='th_back'>2</th>
								<th className='th_back'>3</th>
								<th className='th_back'>4</th>
								<th className='th_back'>5</th>
							</tr>
							<tr>
								<td className='td_back'>Python</td>
								<td>
									<input
										type='radio'
										id='python_0'
										name='python'
										value='0'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
								<td>
									<input
										type='radio'
										id='python_1'
										name='python'
										value='1'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
								<td>
									<input
										type='radio'
										id='python_2'
										name='python'
										value='2'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
								<td>
									<input
										type='radio'
										id='python_3'
										name='python'
										value='3'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
								<td>
									<input
										type='radio'
										id='python_4'
										name='python'
										value='4'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
								<td>
									<input
										type='radio'
										id='python_5'
										name='python'
										value='5'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
							</tr>
							<tr>
								<td>Django</td>
								<td>
									<input
										type='radio'
										id='django_0'
										name='django'
										value='0'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
								<td>
									<input
										type='radio'
										id='django_1'
										name='django'
										value='1'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
								<td>
									<input
										type='radio'
										id='django_2'
										name='django'
										value='2'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
								<td>
									<input
										type='radio'
										id='django_3'
										name='django'
										value='3'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
								<td>
									<input
										type='radio'
										id='django_4'
										name='django'
										value='4'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
								<td>
									<input
										type='radio'
										id='django_5'
										name='django'
										value='5'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
							</tr>
							<tr>
								<td>Celery</td>
								<td>
									<input
										type='radio'
										id='celery_0'
										name='celery'
										value='0'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
								<td>
									<input
										type='radio'
										id='celery_1'
										name='celery'
										value='1'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
								<td>
									<input
										type='radio'
										id='celery_2'
										name='celery'
										value='2'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
								<td>
									<input
										type='radio'
										id='celery_3'
										name='celery'
										value='3'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
								<td>
									<input
										type='radio'
										id='celery_4'
										name='celery'
										value='4'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
								<td>
									<input
										type='radio'
										id='celery_5'
										name='celery'
										value='5'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
							</tr>
							<tr>
								<td className='td_back'>Git</td>
								<td>
									<input
										type='radio'
										id='git_0'
										name='git'
										value='0'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
								<td>
									<input
										type='radio'
										id='git_1'
										name='git'
										value='1'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
								<td>
									<input
										type='radio'
										id='git_2'
										name='git'
										value='2'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
								<td>
									<input
										type='radio'
										id='git_3'
										name='git'
										value='3'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
								<td>
									<input
										type='radio'
										id='git_4'
										name='git'
										value='4'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
								<td>
									<input
										type='radio'
										id='git_5'
										name='git'
										value='5'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
							</tr>
							<tr>
								<td className='td_back'>Unittest</td>
								<td>
									<input
										type='radio'
										id='unittest_0'
										name='unittest'
										value='0'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
								<td>
									<input
										type='radio'
										id='unittest_1'
										name='unittest'
										value='1'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
								<td>
									<input
										type='radio'
										id='unittest_2'
										name='unittest'
										value='2'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
								<td>
									<input
										type='radio'
										id='unittest_3'
										name='unittest'
										value='3'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
								<td>
									<input
										type='radio'
										id='unittest_4'
										name='unittest'
										value='4'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
								<td>
									<input
										type='radio'
										id='unittest_5'
										name='unittest'
										value='5'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
							</tr>
							<tr>
								<td className='td_back'>Signals (django)</td>
								<td>
									<input
										type='radio'
										id='signals_0'
										name='signals'
										value='0'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
								<td>
									<input
										type='radio'
										id='signals_1'
										name='signals'
										value='1'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
								<td>
									<input
										type='radio'
										id='signals_2'
										name='signals'
										value='2'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
								<td>
									<input
										type='radio'
										id='signals_3'
										name='signals'
										value='3'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
								<td>
									<input
										type='radio'
										id='signals_4'
										name='signals'
										value='4'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
								<td>
									<input
										type='radio'
										id='signals_5'
										name='signals'
										value='5'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
							</tr>
							<tr>
								<td className='td_back'>Commands (django)</td>
								<td>
									<input
										type='radio'
										id='commands_0'
										name='commands'
										value='0'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
								<td>
									<input
										type='radio'
										id='commands_1'
										name='commands'
										value='1'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
								<td>
									<input
										type='radio'
										id='commands_2'
										name='commands'
										value='2'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
								<td>
									<input
										type='radio'
										id='commands_3'
										name='commands'
										value='3'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
								<td>
									<input
										type='radio'
										id='commands_4'
										name='commands'
										value='4'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
								<td>
									<input
										type='radio'
										id='commands_5'
										name='commands'
										value='5'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
							</tr>
							<tr>
								<td className='td_back'>Aiogram</td>
								<td>
									<input
										type='radio'
										id='aiogram_0'
										name='aiogram'
										value='0'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
								<td>
									<input
										type='radio'
										id='aiogram_1'
										name='aiogram'
										value='1'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
								<td>
									<input
										type='radio'
										id='aiogram_2'
										name='aiogram'
										value='2'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
								<td>
									<input
										type='radio'
										id='aiogram_3'
										name='aiogram'
										value='3'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
								<td>
									<input
										type='radio'
										id='aiogram_4'
										name='aiogram'
										value='4'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
								<td>
									<input
										type='radio'
										id='aiogram_5'
										name='aiogram'
										value='5'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
							</tr>
							<tr>
								<td className='td_back'>Telebot</td>
								<td>
									<input
										type='radio'
										id='telebot_0'
										name='telebot'
										value='0'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
								<td>
									<input
										type='radio'
										id='telebot_1'
										name='telebot'
										value='1'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
								<td>
									<input
										type='radio'
										id='telebot_2'
										name='telebot'
										value='2'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
								<td>
									<input
										type='radio'
										id='telebot_3'
										name='telebot'
										value='3'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
								<td>
									<input
										type='radio'
										id='telebot_4'
										name='telebot'
										value='4'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
								<td>
									<input
										type='radio'
										id='telebot_5'
										name='telebot'
										value='5'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
							</tr>
							<tr>
								<td className='td_back'>VKBottle</td>
								<td>
									<input
										type='radio'
										id='vkbottle_0'
										name='vkbottle'
										value='0'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
								<td>
									<input
										type='radio'
										id='vkbottle_1'
										name='vkbottle'
										value='1'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
								<td>
									<input
										type='radio'
										id='vkbottle_2'
										name='vkbottle'
										value='2'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
								<td>
									<input
										type='radio'
										id='vkbottle_3'
										name='vkbottle'
										value='3'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
								<td>
									<input
										type='radio'
										id='vkbottle_4'
										name='vkbottle'
										value='4'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
								<td>
									<input
										type='radio'
										id='vkbottle_5'
										name='vkbottle'
										value='5'
										onChange={handleChange}
										className='input_th custom-checkbox2'
									/>
								</td>
							</tr>
						</table>
					</div>
					<div>
						<label htmlFor='moreLanguages_Back'>
							Если владеешь другими языками
							программирования/фреймворками/инструментами, опиши ниже, чем
							больше и подробнее, тем лучше (укажи по 5-балльной шкале уровень
							владения, например: C# 5, Java 2, C++ 0)
						</label>
						<textarea
							id='moreLanguages_Back'
							name='moreLanguages_Back'
							className='dynamic-textarea'
							value={answersForm.moreLanguages_Back}
							onChange={handleChange}
							rows={textareaRows['moreLanguages_Back'] || 1}
							style={{ resize: 'none' }}
						/>
					</div>
					<div>
						<label htmlFor='future_Back'>
							Какой проект ты хотел(а) бы реализовать? С чем он связан и в чем
							его суть?
						</label>
						<textarea
							id='future_Back'
							name='future_Back'
							className='dynamic-textarea'
							value={answersForm.future_Back}
							onChange={handleChange}
							rows={textareaRows['future_Back'] || 1}
							style={{ resize: 'none' }}
						/>
					</div>

					<div>
						<label htmlFor='sides_Back'>
							Расскажи про свои сильные и слабые стороны в работе
						</label>
						<textarea
							id='sides_Back'
							name='sides_Back'
							className='dynamic-textarea'
							value={answersForm.sides_Back}
							onChange={handleChange}
							rows={textareaRows['sides_Back'] || 1}
							style={{ resize: 'none' }}
						/>
					</div>

					<div>
						<label htmlFor='teamwork_Back'>
							Что для тебя самое сложное в командной работе и как ты с этим
							справляешься?
						</label>
						<textarea
							id='teamwork_Back'
							name='teamwork_Back'
							className='dynamic-textarea'
							value={answersForm.teamwork_Back}
							onChange={handleChange}
							rows={textareaRows['teamwork_Back'] || 1}
							style={{ resize: 'none' }}
						/>
					</div>
					<div>
						<label htmlFor='tryhard_Back'>
							Сколько времени ты готов посвящать работе над проектами в рамках
							ITC?
						</label>
						<textarea
							id='tryhard_Back'
							name='tryhard_Back'
							className='dynamic-textarea'
							value={answersForm.tryhard_Back}
							onChange={handleChange}
							rows={textareaRows['tryhard_Back'] || 1}
							style={{ resize: 'none' }}
						/>
					</div>

					<div style={{ color: 'red' }}>{isFormIncomplete && error}</div>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<button
							className='form__button backendButton'
							onClick={handleBack}
							type='button'
						>
							Назад
						</button>
						<button className='form__button backendButton' type='submit'>
							{currentStep === selectedStructures.length - 1
								? 'Отправить'
								: 'Далее'}
						</button>
					</div>
				</form>
				<div className='running-text-container' id='running-text-container'>
					<pre className='text'>
						<code>ITC СНОВА СДЕЛАЛ КРАСИВО</code>
					</pre>
				</div>
			</div>
		</>
	)
}

export default FormBack
