import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const FormProject = ({
	handleNext,
	handleBack,
	handleSubmit,
	currentStep,
	selectedStructures,
}) => {
	const [submited, setSubmited] = useState(false)
	const [error, setError] = useState('')
	const [isEmptyField, setIsEmptyField] = useState(false)
	const [textareaRows4, setTextAreaRows4] = useState({})
	const navigate = useNavigate()

	const [answersForm, setAnswersForm] = useState(() => {
		const storedAnswers = localStorage.getItem('answersFormProject')
		return storedAnswers
			? JSON.parse(storedAnswers)
			: {
					experience_Project: '',
					reasonsWhy_Project: '',
					interaction_Project: '',
					development_Project: '',
					personalQualities_Project: '',
					work_Project: '',
			  }
	})

	const handleChange = e => {
		const { name, value, scrollHeight, clientHeight } = e.target
		const uniqueId = e.target.id

		setAnswersForm(prevState => ({
			...prevState,
			[name]: value,
		}))
		if (scrollHeight > clientHeight) {
			e.target.rows += 1
		}

		localStorage.setItem(
			'textareaRows4',
			JSON.stringify({ ...textareaRows4, [uniqueId]: e.target.rows })
		)
		localStorage.setItem(
			'answersFormProject',
			JSON.stringify({
				...answersForm,
				[name]: value,
			})
		)
	}

	useEffect(() => {
		const savedRows = localStorage.getItem('textareaRows4')
		if (savedRows) {
			const textareas = document.querySelectorAll('.dynamic-textarea')
			textareas.forEach(textarea => {
				const name = textarea.getAttribute('name')
				textarea.rows = parseInt(JSON.parse(savedRows)[name] || 1)
			})
		}
	})

	useEffect(() => {
		const storedAnswers = localStorage.getItem('answersFormProject')
		if (storedAnswers) {
			setAnswersForm(JSON.parse(storedAnswers))
		}
	}, [])

	const onSubmit = async () => {
		const emptyFields = Object.keys(answersForm).filter(
			key =>
				typeof answersForm[key] === 'string' && answersForm[key].trim() === ''
		)
		if (emptyFields.length > 0) {
			setError('Заполните все поля')
			setIsEmptyField(true)
			return
		}

		setIsEmptyField(false)
		const projectData = {
			experience: answersForm.experience_Project,
			motivation: answersForm.reasonsWhy_Project,
			connection: answersForm.interaction_Project,
			characteristics: answersForm.personalQualities_Project,
			projects: answersForm.development_Project,
			time: answersForm.work_Project,
		}

		await handleSubmit(projectData)
	}

	return (
		<>
			<div className='Form Form_Project'>
				<form
					onSubmit={e => {
						e.preventDefault()
						onSubmit()
					}}
					className='survival-school-form'
				>
					<h2 className='p_Project'>Вопросы на Project</h2>

					<div>
						<label htmlFor='experience_Project'>
							Был ли у тебя опыт работы в Project? Если нет, не переживай,
							просто предположи чем занимается проджект. (Развернутый ответ)
						</label>
						<textarea
							id='experience_Project'
							name='experience_Project'
							value={answersForm.experience_Project}
							onChange={handleChange}
							rows={4}
							style={{ resize: 'none' }}
						/>
					</div>
					<div>
						<label htmlFor='reasonsWhy_Project'>
							Почему ты хочешь попасть именно в эту структуру?
						</label>
						<textarea
							id='reasonsWhy_Project'
							name='reasonsWhy_Project'
							value={answersForm.reasonsWhy_Project}
							onChange={handleChange}
							rows={4}
							style={{ resize: 'none' }}
						/>
					</div>

					<div>
						<label htmlFor='interaction_Project'>
							Как ты думаешь, как взаимодействует проджект с остальными
							структурами ITC (Front, Back, Media)?
						</label>
						<textarea
							id='interaction_Project'
							name='interaction_Project'
							value={answersForm.interaction_Project}
							onChange={handleChange}
							rows={4}
							style={{ resize: 'none' }}
						/>
					</div>
					<div>
						<label htmlFor='development_Project'>
							Предложи пару идей развития ITC. Может это идеи каких-то проектов,
							приложений или сайтов, что угодно)
						</label>
						<textarea
							id='development_Project'
							name='development_Project'
							value={answersForm.development_Project}
							onChange={handleChange}
							rows={4}
							style={{ resize: 'none' }}
						/>
					</div>
					<div>
						<label htmlFor='personalQualities_Project'>
							Какие твои качества помогут тебе развиваться в этой сфере? Как ты
							думаешь, какие вообще личные качества нужны хорошему проджекту?
						</label>
						<textarea
							id='personalQualities_Project'
							name='personalQualities_Project'
							value={answersForm.personalQualities_Project}
							onChange={handleChange}
							rows={4}
							style={{ resize: 'none' }}
						/>
					</div>
					<div>
						<label htmlFor='work_Project'>
							Сколько времени ты готов посвящать работе над проектами в рамках
							ITC?
						</label>
						<textarea
							id='work_Project'
							name='work_Project'
							value={answersForm.work_Project}
							onChange={handleChange}
							rows={4}
							style={{ resize: 'none' }}
						/>
					</div>
					<div style={{ color: 'red' }}>{isEmptyField && error}</div>

					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<button
							className='form__button projectButton'
							onClick={handleBack}
							type='button'
						>
							Назад
						</button>
						<button className='form__button projectButton' type='submit'>
							{currentStep === selectedStructures.length - 1
								? 'Отправить'
								: 'Далее'}
						</button>
					</div>
				</form>
				<div className='running-text-container' id='running-text-container'>
					<pre className='text'>
						<code>ITC СНОВА СДЕЛАЛ КРАСИВО</code>
					</pre>
				</div>
			</div>
		</>
	)
}

export default FormProject
