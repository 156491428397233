import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const FormMedia = ({
	handleNext,
	handleBack,
	handleSubmit,
	currentStep,
	selectedStructures,
}) => {
	const [submited, setSubmited] = useState(false)
	const [isEmptyField, setIsEmptyField] = useState(false)
	const [textareaRows3, setTextAreaRows3] = useState({})
	const [error, setError] = useState('')
	const navigate = useNavigate()

	const [answersForm, setAnswersForm] = useState(() => {
		const storedAnswers = localStorage.getItem('answersFormMedia')
		return storedAnswers
			? JSON.parse(storedAnswers)
			: {
					experience_Media: '',
					post_Media: '',
					troubles_Media: '',
					stack_Media: '',
					meme_Media: '',
			  }
	})

	const handleChange = e => {
		const { name, value, checked, scrollHeight, clientHeight } = e.target
		const uniqueId = e.target.id

		if (scrollHeight > clientHeight) {
			e.target.rows += 1
		}
		if (e.target.type === 'checkbox') {
			setAnswersForm(prevState => {
				if (checked) {
					return {
						...prevState,
						stack_Media: {
							...prevState.stack_Media,
							[name]: value,
						},
					}
				} else {
					const { [name]: removedValue, ...rest } = prevState.stack_Media
					return {
						...prevState,
						stack_Media: {
							...rest,
						},
					}
				}
			})
		} else {
			setAnswersForm(prevState => ({
				...prevState,
				[name]: value,
			}))
		}
		setTextAreaRows3(prevState => ({
			...prevState,
			[uniqueId]: e.target.rows,
		}))
		localStorage.setItem(
			'textareaRows3',
			JSON.stringify({ ...textareaRows3, [uniqueId]: e.target.rows })
		)
		localStorage.setItem(
			'answersFormMedia',
			JSON.stringify({
				...answersForm,
				[name]: value,
			})
		)
	}

	useEffect(() => {
		const savedRows = localStorage.getItem('textareaRows3')
		if (savedRows) {
			const textareas = document.querySelectorAll('.dynamic-textarea')
			textareas.forEach(textarea => {
				const name = textarea.getAttribute('name')
				textarea.rows = parseInt(JSON.parse(savedRows)[name] || 1)
			})
		}
	}, [])

	useEffect(() => {
		const storedAnswers = localStorage.getItem('answersFormMedia')
		if (storedAnswers) {
			setAnswersForm(JSON.parse(storedAnswers))
		}
	}, [])

	const onSubmit = async () => {
		const emptyFields = Object.keys(answersForm).filter(
			key =>
				typeof answersForm[key] === 'string' && answersForm[key].trim() === ''
		)
		if (emptyFields.length > 0) {
			setError('Заполните все поля')
			setIsEmptyField(true)
			return
		}

		setIsEmptyField(false)
		const mediaData = {
			experience: answersForm.experience_Media,
			post_itc: answersForm.post_Media,
			difficulties: answersForm.troubles_Media,
			experience_plashki: answersForm.stack_Media,
			mem: answersForm.meme_Media,
		}

		await handleSubmit(mediaData)
	}

	return (
		<>
			<div className='Form Form_Media'>
				<form
					onSubmit={e => {
						e.preventDefault()
						onSubmit()
					}}
					className='survival-school-form'
				>
					<h2 className='p_Media'>Вопросы на Media</h2>

					<div>
						<label htmlFor='experience_Media'>
							Есть ли опыт работы в медиа? Если да, опиши его или скинь своё
							портфолио
						</label>
						<textarea
							id='experience_Media'
							name='experience_Media'
							value={answersForm.experience_Media}
							onChange={handleChange}
							rows={4}
							style={{ resize: 'none' }}
						/>
					</div>
					<div>
						<label htmlFor='post_Media'>
							Придумай тему для поста в сообщество IT-Center
						</label>
						<textarea
							id='post_Media'
							name='post_Media'
							value={answersForm.post_Media}
							onChange={handleChange}
							rows={4}
							style={{ resize: 'none' }}
						/>
					</div>
					<div>
						<label htmlFor='troubles_Media'>
							Бывают ли у тебя трудности в генерации новых идей для постов? Как
							много времени тебе понадобилось на предыдущий вопрос?
						</label>
						<textarea
							id='troubles_Media'
							name='troubles_Media'
							value={answersForm.troubles_Media}
							onChange={handleChange}
							rows={4}
							style={{ resize: 'none' }}
						/>
					</div>
					<div>
						<label htmlFor='stack_Media'>
							Есть ли опыт в дизайне плашек? Если да, в каких программах обычно
							работаешь?
						</label>
						<textarea
							id='stack_Media'
							name='stack_Media'
							value={answersForm.stack_Media}
							onChange={handleChange}
							rows={4}
							style={{ resize: 'none' }}
						/>
					</div>
					<div>
						<label htmlFor='meme_Media'>Назови свой любимый мем</label>
						<textarea
							id='meme_Media'
							name='meme_Media'
							value={answersForm.meme_Media}
							onChange={handleChange}
							rows={4}
							style={{ resize: 'none' }}
						/>
					</div>
					<div style={{ color: 'red' }}>{isEmptyField && error}</div>

					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<button
							className='form__button mediaButton'
							onClick={handleBack}
							type='button'
						>
							Назад
						</button>
						<button className='form__button mediaButton' type='submit'>
							{currentStep === selectedStructures.length - 1
								? 'Отправить'
								: 'Далее'}
						</button>
					</div>
				</form>
				<div className='running-text-container' id='running-text-container'>
					<pre className='text'>
						<code>ITC СНОВА СДЕЛАЛ КРАСИВО</code>
					</pre>
				</div>
			</div>
		</>
	)
}

export default FormMedia
