import React, { useEffect, useState } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import Form from './components/Form/Form'
import MainPage from './components/MainPage/MainPage'
import MainQuestions from './components/MainQuestions/MainQuestions'
import MentoringForm from './components/Mentoring/MentoringForm'
import MentoringMainQuestions from './components/Mentoring/MentoringMainQuestions'
import ScrollToTop from './components/scroll'

function App() {
	const [questionAnswers, setQuestionAnswers] = useState({})
	const [showSuccessMessage, setShowSuccessMessage] = useState(false)
	const [error, setError] = useState(false)
	const navigate = useNavigate()
	const location = useLocation()

	useEffect(() => {
		navigate('/')
	}, [])

	function goQuestions() {
		navigate('/questions')
	}

	function goMentoringQuestions() {
		navigate('/mentoring')
	}

	function goCheck() {
		navigate('/checking')
	}
	function goForm() {
		navigate('/form')
	}

	function goMentoringForm() {
		navigate('/mentoringform')
	}

	const handleSelectQuestions = questionAnswers => {
		setQuestionAnswers(questionAnswers)
	}

	const sendDataToBackend = async () => {
		const formAnswers = JSON.parse(localStorage.getItem('answersForm'))
		const combinedAnswers = { ...questionAnswers, ...formAnswers }
		const formData = new FormData()

		const languagesBackString = JSON.stringify(combinedAnswers.languages_Back)
		const languagesFrontString = JSON.stringify(combinedAnswers.languages_Front)
		const stackMediaString = JSON.stringify(combinedAnswers.stack_Media)

		formData.set('languages_Back', languagesBackString)
		formData.set('languages_Front', languagesFrontString)
		formData.set('stack_Media', stackMediaString)

		Object.entries(combinedAnswers).forEach(([key, value]) => {
			if (
				key !== 'languages_Back' &&
				key !== 'languages_Front' &&
				key !== 'stack_Media'
			) {
				formData.set(key, value)
			}
		})

		try {
			const response = await fetch('https://nabor-back.itc-hub.ru/usersave', {
				method: 'POST',
				body: formData,
			})

			if (response.ok) {
				setShowSuccessMessage(true)
				navigate('/')
				window.scrollTo(0, 0)
				setTimeout(() => {
					setShowSuccessMessage(false)
				}, 2000)
			}
		} catch (error) {
			console.error('Error sending data to backend:', error)
			setError(true)
		}
		localStorage.removeItem('answers')
		localStorage.removeItem('answersForm')
		localStorage.removeItem('textareaRows')
		localStorage.removeItem('textareaRows1')
		localStorage.removeItem('textareaRows2')
		localStorage.removeItem('textareaRows3')
		localStorage.removeItem('textareaRows4')
	}

	useEffect(() => {
		const color =
			location.pathname === '/'
				? 'black'
				: location.pathname === '/questions'
				? '#131313'
				: 'black'
		document.body.style.backgroundColor = color
		return () => {
			document.body.style.backgroundColor = ''
		}
	}, [location.pathname])

	return (
		<div>
			<Routes>
				<Route
					path='/'
					element={
						<MainPage
							goQuestions={goQuestions}
							goMentoringQuestions={goMentoringQuestions}
							goCheck={goCheck}
						/>
					}
				/>
				<Route
					path='/mentoring'
					element={
						<MentoringMainQuestions
							goMentoringForm={goMentoringForm}
							handleSelectQuestions={handleSelectQuestions}
						/>
					}
				/>
				<Route
					path='/questions'
					element={
						<MainQuestions
							goForm={goForm}
							handleSelectQuestions={handleSelectQuestions}
						/>
					}
				/>
				<Route
					path='/form'
					element={<Form sendDataToBackend={sendDataToBackend} />}
				/>
				<Route
					path='/mentoringform'
					element={<MentoringForm sendDataToBackend={sendDataToBackend} />}
				/>
			</Routes>
			<ScrollToTop />
			{showSuccessMessage && (
				<div className='message'>Ваша анкета отправлена</div>
			)}
			{error && (
				<div className='message1'>
					Возникла ошибка, попробуй заполнить еще раз
				</div>
			)}
		</div>
	)
}

export default App
