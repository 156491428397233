import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const MentoringFormBack = ({
	handleNext,
	handleBack,
	handleSubmit,
	currentStep,
	selectedStructures,
}) => {
	const [submited, setSubmited] = useState(false)
	const [error, setError] = useState('')
	const [textareaRows, setTextAreaRows] = useState({})
	const navigate = useNavigate()

	const [answersForm, setAnswersForm] = useState(() => {
		const storedAnswers = localStorage.getItem('answersFormMentoringBack')
		return storedAnswers
			? JSON.parse(storedAnswers)
			: {
					tasks_Mentoring_Back: '',
					important_Mentoring_Back: '',
					tryhard_Mentoring_Back: '',
					connection_Mentoring_Back: '',
					sides_Mentoring_Back: '',
					codeExperience_Mentoring_Back: '',
					reasonsWhy_Mentoring_Back: '',
					experience_Mentoring_Back: '',
					future_Mentoring_Back: '',
			  }
	})

	const handleChange = e => {
		const { name, value, scrollHeight, clientHeight } = e.target
		const uniqueId = e.target.id

		if (scrollHeight > clientHeight) {
			e.target.rows += 1
		}
		// Обновите состояние каждой textarea, используя уникальный идентификатор
		if (e.target.type === 'radio') {
			setAnswersForm(prevState => ({
				...prevState,
				languages_Back: {
					...prevState.languages_Back,
					[name]: value,
				},
			}))
		} else {
			setAnswersForm(prevState => ({
				...prevState,
				[name]: value,
			}))
		}

		// Обновите количество строк в каждой textarea
		setTextAreaRows(prevState => ({
			...prevState,
			[uniqueId]: e.target.rows,
		}))

		localStorage.setItem(
			'textareaRowsMentoringBack',
			JSON.stringify({ ...textareaRows, [uniqueId]: e.target.rows })
		)
		localStorage.setItem(
			'answersFormMentoringBack',
			JSON.stringify({
				...answersForm,
				[name]: value,
			})
		)
	}

	useEffect(() => {
		const savedRows = localStorage.getItem('textareaRowsMentoringBack')
		if (savedRows) {
			const textareas = document.querySelectorAll('.dynamic-textarea')
			textareas.forEach(textarea => {
				const name = textarea.getAttribute('name')
				textarea.rows = parseInt(JSON.parse(savedRows)[name] || 1)
			})
		}
	}, [])

	useEffect(() => {
		localStorage.setItem(
			'answersFormMentoringBack',
			JSON.stringify(answersForm)
		)
	}, [answersForm])

	useEffect(() => {
		const storedAnswers = localStorage.getItem('answersFormMentoringBack')
		if (storedAnswers) {
			setAnswersForm(JSON.parse(storedAnswers))
		}
	}, [])

	const onSubmit = async () => {
		const requiredFields = [
			'tasks_Mentoring_Back',
			'important_Mentoring_Back',
			'connection_Mentoring_Back',
			'sides_Mentoring_Back',
			'codeExperience_Mentoring_Back',
			'reasonsWhy_Mentoring_Back',
			'experience_Mentoring_Back',
			'tryhard_Mentoring_Back',
		]

		const emptyFields = requiredFields.filter(
			key => !answersForm[key] || answersForm[key].trim() === ''
		)

		if (emptyFields.length > 0) {
			setError('Заполните все поля')
			return
		}

		const backData = {
			cases: answersForm.tasks_Mentoring_Back,
			important: answersForm.important_Mentoring_Back,
			interaction: answersForm.connection_Mentoring_Back,
			experience_code: answersForm.codeExperience_Mentoring_Back,
			pros_cons: answersForm.sides_Mentoring_Back,
			why_mentoring: answersForm.reasonsWhy_Mentoring_Back,
			experience: answersForm.experience_Mentoring_Back,
			after_mentoring: answersForm.future_Mentoring_Back,
			hardworking: answersForm.tryhard_Mentoring_Back,
		}

		await handleSubmit(backData)

		if (currentStep < selectedStructures.length - 1) {
			handleNext()
		}
	}

	return (
		<>
			<div className='Form Form_Backend'>
				<form
					onSubmit={e => {
						e.preventDefault()
						onSubmit()
					}}
					className='survival-school-form'
				>
					<h2 className='p_Back'>Вопросы на Backend</h2>

					<div>
						<label htmlFor='tasks_Mentoring_Back'>
							Чем по твоему мнению занимается backend разработчик? Какие у него
							задачи и зоны ответственности? Этот вопрос больше для тебя "на
							подумать", поэтому ничего страшного, если не знаешь наверняка,
							можно не гуглить, а просто предположить
						</label>
						<textarea
							id='tasks_Mentoring_Back'
							name='tasks_Mentoring_Back'
							className='dynamic-textarea'
							value={answersForm.tasks_Mentoring_Back}
							onChange={handleChange}
							rows={textareaRows['tasks_Mentoring_Back'] || 1}
							style={{ resize: 'none' }}
						/>
					</div>
					<div>
						<label htmlFor='important_Mentoring_Back'>
							Что для тебя наиболее важно в процессе обучения?
						</label>
						<textarea
							id='important_Mentoring_Back'
							name='important_Mentoring_Back'
							className='dynamic-textarea'
							value={answersForm.important_Mentoring_Back}
							onChange={handleChange}
							rows={textareaRows['important_Mentoring_Back'] || 1}
							style={{ resize: 'none' }}
						/>
					</div>
					<div>
						<label htmlFor='connection_Mentoring_Back'>
							Опиши взаимодействие frontend и backend частей, если не знаешь, то
							просто предположи
						</label>
						<textarea
							id='connection_Mentoring_Back'
							name='connection_Mentoring_Back'
							className='dynamic-textarea'
							value={answersForm.connection_Mentoring_Back}
							onChange={handleChange}
							rows={textareaRows['connection_Mentoring_Back'] || 1}
							style={{ resize: 'none' }}
						/>
					</div>
					<div>
						<label htmlFor='sides_Mentoring_Back'>
							Какие твои сильные и слабые стороны?
						</label>
						<textarea
							id='sides_Mentoring_Back'
							name='sides_Mentoring_Back'
							className='dynamic-textarea'
							value={answersForm.sides_Mentoring_Back}
							onChange={handleChange}
							rows={textareaRows['sides_Mentoring_Back'] || 1}
							style={{ resize: 'none' }}
						/>
					</div>

					<div>
						<label htmlFor='codeExperience_Mentoring_Back'>
							Был ли у тебя опыт в написании кода? Если да, то опиши его, чем
							подробнее, тем лучше)
						</label>
						<textarea
							id='codeExperience_Mentoring_Back'
							name='codeExperience_Mentoring_Back'
							className='dynamic-textarea'
							value={answersForm.codeExperience_Mentoring_Back}
							onChange={handleChange}
							rows={textareaRows['codeExperience_Mentoring_Back'] || 1}
							style={{ resize: 'none' }}
						/>
					</div>

					<div>
						<label htmlFor='reasonsWhy_Mentoring_Back'>
							Почему ты решил(а), что тебе подходит именно менторинг, чему бы ты
							хотел(а) научиться (ответ "всему" не подойдет, поскольку чем лучше
							ты сформулируешь свои желания и цели, тем проще тебе будет
							учиться)?
						</label>
						<textarea
							id='reasonsWhy_Mentoring_Back'
							name='reasonsWhy_Mentoring_Back'
							className='dynamic-textarea'
							value={answersForm.reasonsWhy_Mentoring_Back}
							onChange={handleChange}
							rows={textareaRows['reasonsWhy_Mentoring_Back'] || 1}
							style={{ resize: 'none' }}
						/>
					</div>
					<div>
						<label htmlFor='experience_Mentoring_Back'>
							Есть ли у тебя опыт студенческой активности? А может, у тебя были
							какие-то проекты в школьные времена, в детских лагерях? В ответе
							можно смело хвастаться всем, чем ты посчитаешь нужным!
						</label>
						<textarea
							id='experience_Mentoring_Back'
							name='experience_Mentoring_Back'
							className='dynamic-textarea'
							value={answersForm.experience_Mentoring_Back}
							onChange={handleChange}
							rows={textareaRows['experience_Mentoring_Back'] || 1}
							style={{ resize: 'none' }}
						/>
					</div>
					<div>
						<label htmlFor='tryhard_Mentoring_Back'>
							В любом обучении важны постоянство и упорство. Готов(а) ли ты
							регулярно посещать лекции и выполнять домашние задания в рамках
							обучения? А что будешь делать, если понимаешь, что никак не
							успеваешь посетить занятие или сдать что-то в нужное время?
						</label>
						<textarea
							id='tryhard_Mentoring_Back'
							name='tryhard_Mentoring_Back'
							value={answersForm.tryhard_Mentoring_Back}
							onChange={handleChange}
							rows={4}
							style={{ resize: 'none' }}
						/>
					</div>

					{/* Показываем общую ошибку */}
					<div style={{ color: 'red' }}>{error}</div>

					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<button
							className='form__button backendButton'
							onClick={handleBack}
							type='button'
						>
							Назад
						</button>
						<button className='form__button backendButton' type='submit'>
							{currentStep === selectedStructures.length - 1
								? 'Отправить'
								: 'Далее'}
						</button>
					</div>
				</form>
				<div className='running-text-container' id='running-text-container'>
					<pre className='text'>
						<code>ITC СНОВА СДЕЛАЛ КРАСИВО</code>
					</pre>
				</div>
			</div>
		</>
	)
}

export default MentoringFormBack
